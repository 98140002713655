.lobby-subtitle {
    font-size: 1.5rem;
    color: #8d6e63;
    margin-top: 0.5rem;
}

.lobby-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
}

.burger-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.lobby-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 80%;
    max-width: 300px;
    margin-bottom: 2rem;
}

.lobby-actions button {
    padding: 1rem;
    border-radius: 35px;
    border: none;
    font-size: 3rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
}

.start-button {
    background-color: #ff7043;
    color: white;
    box-shadow: 0 4px 0 #e64a19;
}

.start-button:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 0 #e64a19;
}

.start-button:active {
    transform: translateY(0);
    box-shadow: 0 0 0 #e64a19;
}

.game-description {
    text-align: center;
    line-height: 1.6;
    font-size: 1.1rem;
}

h2 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
}
