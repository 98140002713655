.aacboard-selected-items {
    margin-bottom: 1rem;
    font-weight: bold;
    min-height: 140px;
    border: 2px solid #ddd;
    border-radius: 6px;
    padding: 1rem;
    width: 30rem;
}

.aacboard-selected-item {
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
    padding: 0.5rem 0.75rem;
    border: 2px solid #ccc;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    font-size: 1.3rem;

    img {
        width: 2.5rem;
        height: 2.5rem;
        object-fit: contain;
    }
}

.selected-item-delete {
    margin-left: 0.5rem;
    cursor: pointer;
    border: none;
    background: transparent;
    font-weight: bold;
    font-size: 1.2rem;
}

.aacboard-grid {
    max-width: 750px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 0.5rem;
}

.aacboard-item-btn {
    aspect-ratio: 1 / 1;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;

    img {
        width: 63px;
        height: 63px;
        object-fit: contain;
    }
}

.AACBoardContainer {
    margin-left: -20rem;
    margin-bottom: 20rem;
    z-index: 9999;
}
