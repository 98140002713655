.app-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    padding: 1rem;
    margin: 0 auto;
    box-sizing: border-box;
}


.columns {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}


.column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.right-column {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.Score {
    font-size: 36px;
    border-radius: 6px;
    color: white;
    background-color: #9dae88;
    border: 2px solid black;
    text-align: center;
    width: fit-content;
    padding: 10px;
    margin-left: -30rem;

}


.SendCustomerOrder {
    bottom: 10px;
    right: 350px;
    position: absolute;
    width: 200px;
    height: auto;
    margin: 10px;
    padding: 0;
    border: None;
    cursor: pointer;
    text-align: center;
}

.customer-container {
    position: relative;
    width: 950px;
}

.customer-image {
    width: 75%;
    max-width: 300rem;
    height: auto;
    margin-bottom: 0rem;
    margin-left: -5rem;
}

.customer-mini-order-overlay {
    top: 0px;
    right: 0px;
    position: absolute;
    max-width: 350px;
    margin-top: 25rem;
    margin-right: 25rem;
    z-index: 30000;
}

.manager-mini-order-overlay {
    bottom: 20px;
    left: 10px;
    position: absolute;
    max-width: 350px;
}
