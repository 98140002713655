.mini-order-display {
    position: fixed;
    bottom: 30rem;
    right: 40rem;
    display: flex;
    align-items: center;
    gap: 20px;
    z-index: 2001;
    transform: scale(0.7);
    z-index: 2001;

    >* {
        all: unset;
        width: 100px !important;
        height: 200px !important;
        display: flex;
        align-items: end;

        .TableImages {
            all: unset;
            width: 100%;
        }

        >* {
            width: 100%
        }
    }

    .BurgerDisplay {
        flex-direction: column-reverse;
    }

    .Cup {
        border: 2px solid #000000;
        border-radius: 15px;
        background-color: #ffffff;
        overflow: hidden;
        position: relative;
        width: 50px;
        height: 10px;
    }

    .CupContainer {
        width: 50px;
        height: 100px;

    }
}

.relative-container {
    position: relative;
    display: inline-block;

}


.mini-order-display {
    position: absolute;
    bottom: 15rem;
    right: -20%;
    z-index: 2001;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transform: scale(.7);
}
