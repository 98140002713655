.DrinkBuilder {
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.DrinkButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DrinkButtonsContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: nowrap;
}

.DispenserImage {
    width: 25px;
    height: auto;
    margin-top: -10px;
}

.MainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.CupSizeContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 150px;
}

.DrinkDisplayContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-grow: 1;
    max-width: 30vw;
}

.ActionButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 150px;
}

.DrinkButtons button {
    min-width: 120px;
    min-height: 120px;
    margin: 10px;
    padding: 0;
    border: 3px solid #322e2e;
    background: #ffffff;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
    font-size: 32px;
}

.DrinkButtons button:active {
    transform: scale(.95);
}

.DrinkButtons button:disabled {
    cursor: not-allowed;
    transform: none;
}

.ClearCupButton {
    min-width: 120px;
    min-height: 120px;
    margin: 10px;
    padding: 0;
    border: 1px solid #322e2e;
    background: #ffffff;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
    font-size: 24px;
}


.Cup.small {
    height: 200px;
}

.Cup.medium {
    height: 275px;
}

.Cup.large {
    height: 350px;
}

.Filling {
    width: 100%;
    position: absolute;
    bottom: 0;
    transition: height 0.3s ease, background-color 0.3s ease;
}

.FillCupButton {
    min-width: 120px;
    min-height: 120px;
    margin: 10px;
    padding: 0;
    border: 1px solid #322e2e;
    background: #ffffff;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
    font-size: 24px;
}

.FillCupButton:disabled {
    cursor: not-allowed;
    transform: none !important;
}

.FillCupButton:hover {
    border: 3px solid #322e2e;
}

.FillCupButton:hover:disabled {
    border: 1px solid #322e2e;
}

.FillCupButton:active {
    transform: scale(.95);
}

.ClearCupButton:active {
    transform: scale(.95);
}


.CupSizeButtons:hover {
    background: #f3f3f3;
}

.CupSizeButtons:active {
    transform: scale(.95);
}

.SendButton {
    min-width: 120px;
    min-height: 40px;
    margin: 10px;
    padding: 0;
    border: 1px solid #322e2e;
    background: #ffffff;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
}

.ScoreText {
    font-size: 36px;
    border-radius: 6px;
    color: white;
    background-color: #9dae88;
    border: 2px solid;
    border-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: fit-content;
    margin: auto;
    margin-top: 10px;
    padding: 10px;
}

.CupSizeButtons {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    min-height: 120px;
    margin: 10px;
    border: 1px solid #322e2e;
    background: #ffffff;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
}

.CupSizeButtons:disabled {
    cursor: not-allowed;
    transform: none;
    border: 1px solid #322e2e;
}

.CupSizeImageSmall {
    width: 85px;
    height: 85px;
    object-fit: contain;
}

.CupSizeImageMedium {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.CupSizeImageLarge {
    width: 115px;
    height: 115px;
    object-fit: contain;
}

.ButtonHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.FillCupImage {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.ClearCupButton {
    border: none;
}

.ClearCupImage {
    height: 120px;
}

.CupContainer {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: flex-end;
}

.CupContainer.small {
    width: 150px;
    height: 300px;
}

.CupContainer.medium {
    width: 175px;
    height: 350px;
}

.CupContainer.large {
    width: 200px;
    height: 400px;
}

.CupImages {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    max-width: 5rem;
}

.FillOverlay {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 0;
    background-color: transparent;
    transition: height 0.3s ease-in-out;
    z-index: 5;
    max-height: 7rem;
    max-width: 4rem;
}

.ConfirmMessage {
    text-align: center;
    font-family: "Comic Sans MS";
    font-size: 32px;
    color: #84d838;
    background-color: #ffffff;
    border-radius: 5px;
    transition: opacity 1s ease-in-out;
}
