.scroll-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
}

.scroll-button {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    padding: 1rem;
    user-select: none;
}

.selected-image img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin: 0.5rem 0;
}
