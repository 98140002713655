.BurgerBuilder {
    text-align: center;
}

.IngredientButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.IngredientButtons button {
    min-width: 120px;
    min-height: 120px;
    margin: 10px;
    padding: 0;
    border: 1px solid #322e2e;
    background: #ffffff;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
}


.IngredientButtons button img {
    width: 50px;
    height: 50px;
    display: block;
    margin: 0 auto;
}

.IngredientsButtons button p {
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold;
}

.Burger {
    margin: 20px auto;
    width: 200px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    border: none;
    padding: 10px;
    background-color: transparent;
    position: relative;
    z-index: 1;
}

.BurgerIngredient {
    width: 180px;
    margin: -5px 0;
    border-radius: 5px;
    z-index: 1;
}

.BurgerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Plate {
    width: 240px;
    height: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    margin: -2.5em auto 1.5em;
    position: relative;
    top: 0;
    box-shadow: 0 8px 15px #000000;
}

.ClearPlateButton {
    min-width: 120px;
    min-height: 40px;
    margin: 10px;
    padding: 0;
    border: 1px solid #322e2e;
    background: #ffffff;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
}

.BottomButtons {
    min-width: 120px;
    min-height: 40px;
    margin: 10px;
    padding: 0;
    border: 1px solid #322e2e;
    background: #ffffff;
    cursor: pointer;
    text-align: center;
    border-radius: 6px;
}

.BurgerStation {
    position: relative;
    width: 600px;
    margin: 0 auto;
    height: 400px;
}

.GrillImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 600px;
    height: 400px;
    z-index: 0;
}

.BurgerStack {
    position: absolute;
    top: 50%;
    left: 12%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    z-index: 1;
    height: 90%;
}

.BurgerDisplay {
    display: flex;
    flex-direction: column-reverse;
}

.IngredientOnGrill {
    width: 80px;
    height: auto;
    margin: -3px 0;
}

.ScoreText {
    font-size: 36px;
    border-radius: 6px;
    color: white;
    background-color: #9dae88;
    border: 2px solid;
    border-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: fit-content;
    margin: auto;
    margin-top: 10px;
    padding: 10px;
}

.SendCustomerOrderImage{
    height: 100px;
}

.RepeatOrderImage{
    height: 100px;
}

.ClearPlateImage{
    height: 100px;
}

.SendImage{
    height: 100px;
}

.SendOrderButton{
    background: white;
    border: none;
    cursor: pointer;
}
